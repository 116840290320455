import React, { FC, useEffect, useRef, useCallback } from 'react';
import { PageProps } from 'gatsby';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Layout } from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faAt } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';
import gsap from 'gsap';
import { SEO } from '../components/SEO';

import '../styles/index.scss';

export const Home: FC<
  PageProps<GatsbyTypes.AllContentfulBlogPostIndexQuery>
> = ({ data }) => {
  const heroCatch = useRef(null);
  const recentBlogs = useRef<HTMLTableSectionElement>(null);

  const handleScrollToRectonBlogs = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();

    if (recentBlogs == null) {
      return;
    }

    if (recentBlogs.current == null) {
      return;
    }

    const top =
      recentBlogs.current.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    gsap.to(heroCatch.current, { y: -100, duration: 1, opacity: 1 });
  });

  return (
    <Layout>
      <SEO />
      <section className="hero">
        <div ref={heroCatch} className="hero__catch">
          <h1 className="hero__catch-h1">
            ONE DAY A WEEK TRAINING,
            <br />
            IT'S BETTER THAN NOTHING.
          </h1>
          <ul className="hero__sns-ul">
            <li className="hero__sns-li">
              <a
                className="hero__sns-a"
                target="_blank"
                href="https://twitter.com/shimu_dev"
                rel="noopener noreferrer"
                aria-label="My twitter profile"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                className="hero__sns-a"
                target="_blank"
                href="mailto: blog@shimu.dev"
                rel="noopener noreferrer"
                aria-label="My Email address"
              >
                <FontAwesomeIcon icon={faAt} />
              </a>
              <a
                className="hero__sns-a"
                target="_blank"
                href="https://github.com/shimudev"
                rel="noopener noreferrer"
                aria-label="My Github Profile"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section className="arrow">
        <FontAwesomeIcon
          icon={faChevronDown}
          className="arrow__icon"
          onClick={handleScrollToRectonBlogs}
        />
      </section>
      <section ref={recentBlogs} id="recent-blogs" className="recent-blogs">
        <h1 className="recent-blogs__title">RECENT BLOGS</h1>
        {data.allContentfulBlogPost.edges.map(({ node }) => (
          <article className="recent-blogs__card" key={node.id}>
            <Link
              className="recent-blogs__card-link"
              to={`/blog/post/${node.slug}`}
            >
              <div className="recent-blogs__card-header">
                <figure>
                  {node?.eyecatch?.fluid ? (
                    <Img
                      fluid={node.eyecatch.fluid}
                      style={{ width: '100%', height: '300px' }}
                    />
                  ) : (
                    ''
                  )}
                </figure>
                <div className="recent-blogs__card-header-content">
                  <time
                    className="recent-blogs__card-date"
                    dateTime={node.publishDate}
                  >
                    DATE {node.publishDateJP}
                  </time>
                  <h2 className="recent-blogs__card-title">{node.title}</h2>
                </div>
              </div>
              <div className="recent-blogs_card-body">
                <p className="recent-blogs__card-desc">
                  {node?.contentMarkdown?.childMarkdownRemark?.excerpt}
                </p>
              </div>
            </Link>
          </article>
        ))}
      </section>
    </Layout>
  );
};

export const query = graphql`
  query AllContentfulBlogPostIndex {
    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      skip: 0
      limit: 4
    ) {
      edges {
        node {
          title
          id
          slug
          publishDateJP: publishDate(formatString: "YYYY月MM月DD日")
          publishDate
          contentMarkdown {
            childMarkdownRemark {
              content: html
              excerpt(pruneLength: 70)
            }
          }
          eyecatch {
            fluid(maxWidth: 573) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`;

export default Home;
